angular.module("app")
    .controller("MapController", function($timeout, $scope, $rootScope, $uibModal, _mapFieldService, _mapService,
    _view, _v, _apiService, _flashService, Notification, _i18n, _appStorage, _calendar, gettextCatalog) {


        $scope.$on("_view:urlReady", function () {
            $scope.mapLoading = true;

            var successMessage;
            if (successMessage = _flashService.get("successMessage")) {
                Notification.success(successMessage);
            }
            var failMessage;
            if (failMessage = _flashService.get("failMessage")) {
                Notification.error(failMessage);
            }

            _mapFieldService.init(_view.getLatLngZoom());

            $scope.isWMSLayer = _appStorage.get("isWMSLayer");
            if (_appStorage.get("isWMSLayer")) {
                _mapFieldService.switchWMSOverlay(true);
            }

            init();
        });

        $scope.$on("_view:fieldChanged", function () {
            loadNDVI();
            colorActiveField();
        });

        $scope.$on("_view:panelFieldChanged", function () {
            loadNDVI();
            colorActiveField();
        });

        $scope.$on("_calendar:changed", function () {
            _mapService.colorFields();
            updatePhotoNotes();

            if (_appStorage.get("isNDVILayer")) {
                var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : (_view.isPanelFieldSelected() ? _view.getPanelField().id : null);
                if (currentFieldId) {
                    _calendar.itemsPromise.then(function(items) {
                        var date = _.find(items, function (i) { return i.current; }).date;
                        _mapFieldService.switchNDVIOverlay(true, currentFieldId, date);
                    });
                }
            }
        });

        $scope.$on("mapService:idle", function (event, data) {
            updatePhotoNotes();
        });

        $scope.$on("_view:editSprayChanged", function (event, data) {
            updatePhotoNotes();
        });

        $scope.$on("_view:viewSprayChanged", function (event, data) {
            updatePhotoNotes();
        });

        $scope.$on("_view:addCropMonitorChanged", function (event, data) {
            updatePhotoNotes();
        });

        function updatePhotoNotes() {
            if (_view.is.map()) {
                _calendar.getDisplayedDateBoundariesPromise().then(function (calendarBoundaries) {
                    if (calendarBoundaries != null) {
                        var bounds = _mapService.getBounds();
                        if (bounds) {
                            if (!_view.is.editMap() && !_view.is.editSpray() && !_view.is.viewSpray() && !_view.is.addCropMonitor()) {
                                _apiService.listPhotoNote(_view.getCurrentAccount(), calendarBoundaries.from, calendarBoundaries.to, bounds[0], bounds[1], bounds[2], bounds[3]).then(function (d) {
                                    _mapFieldService.drawPhotoNotes(d.data);
                                    $rootScope.$broadcast("_map:photoNotesReady", d.data);
                                }, function () {
                                    Notification.error({message: _i18n.getString("common.notifications.apiError")});
                                });
                            } else {
                                _mapFieldService.drawPhotoNotes([]); //clear
                            }
                        }
                    }
                });
            }
        }

        function loadNDVI() {
            $scope.isNDVILayer = _appStorage.get("isNDVILayer");
            if (_appStorage.get("isNDVILayer")) {
                var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : (_view.isPanelFieldSelected() ? _view.getPanelField().id : null);
                if (currentFieldId) {
                    _calendar.itemsPromise.then(function(items) {
                        var date = _.find(items, function (i) { return i.current; }).date;
                        _apiService.ndvi(_view.getCurrentAccount(), currentFieldId).then(function (d) {
                            _mapFieldService.setNDVIData(d.data);
                            _mapFieldService.switchNDVIOverlay(true, currentFieldId, date);
                        }, function () {
                            Notification.error({message: _i18n.getString("common.notifications.apiError"), delay: null});
                        });
                    });
                }
            }
        }

        function colorActiveField(){
            var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : (_view.isPanelFieldSelected() ? _view.getPanelField().id : null);
            if (currentFieldId){
                _mapService.colorActiveField(currentFieldId)
            }
        }

        $scope.switchWMSLayer = function() {
            if (_appStorage.get("isWMSLayer")) {
                _appStorage.set("isWMSLayer", false);
                _mapFieldService.switchWMSOverlay(false);
            } else {
                _appStorage.set("isWMSLayer", true);
                _mapFieldService.switchWMSOverlay(true);
            }
            $scope.isWMSLayer = _appStorage.get("isWMSLayer");
        };

        $scope.switchNDVILayer = function() {
            if (_appStorage.get("isNDVILayer")) {
                _appStorage.set("isNDVILayer", false);
                _mapFieldService.switchNDVIOverlay(false);
            } else {
                _appStorage.set("isNDVILayer", true);
                loadNDVI();
            }
            $scope.isNDVILayer = _appStorage.get("isNDVILayer");
        };

        $scope.$on("panRequiredEvent", function () {
            //console.log("panRequiredEvent in map");
            panToCurrent();
            if (_view.is.editMap() && _view.getCurrentField()) {
                _mapFieldService.editField(_view.getCurrentField().id);
            }
        });

        $scope.$on("aside:editMap", function () {
            $scope.mapLoading = true;

            _view.setEditMap(true);
            $timeout(function() {
                _view.refreshMinHeight();
                $timeout(function() {
                    _mapFieldService.resize();
                    _mapFieldService.editMap();
                    var currentFieldId = (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) ? _view.getCurrentField().id : (_view.isPanelFieldSelected() ? _view.getPanelField().id : null);
                    if (currentFieldId) {
                        _mapFieldService.editField(currentFieldId);
                    }
                    $scope.mapLoading = false;
                })
            });
        });

        $scope.$on("cEditMapTools:cancel", function () {

            var $uibModalInstance = $uibModal.open({
                openedClass: 'modal-open modal-cancelMapEdit',
                templateUrl: 'modal-cancelMapEdit',
                controllerAs: 'vm',
                controller: function($uibModalInstance) {
                    var vm = this;

                    // Close without Saving
                    vm.close = function() {
                        $uibModalInstance.close('close');
                    };

                    // Save
                    vm.save = function() {
                        $uibModalInstance.close('save');
                    };

                    // Cancel
                    vm.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            });

            $uibModalInstance.result.then(function (msg) {
                if (msg == 'close') {
                    $scope.mapLoading = true;
                    returnToMapView();
                } else {
                    saveChangesToMap();
                }
            });
        });

        $scope.$on("cEditMapTools:save", saveChangesToMap);

        $scope.$on('mapService:fieldCompleted', function(event, data) {
            $rootScope.$safeApply(function() {
                var cnt = 1;
                _view.getFields().forEach(field => {
                    var regex = new RegExp(gettextCatalog.getString("New field") + " (\\d+)", "g");
                    var match = regex.exec(field.name);
                    if (match) {
                        cnt = Math.max(cnt, parseInt(match[1]) + 1);
                    }
                });

                var field = _.defaults(data[0], {
                    newField: true,
                    id: _.uniqueId("field"),
                    name: gettextCatalog.getString("New field") + " " + cnt
                });

                if (_view.getCurrentFarm()) {
                    field.farm = {id: _view.getCurrentFarm().id, name: _view.getCurrentFarm().name};
                }

                _view.addField(field);
                _mapFieldService.highlightField(field);
                _v.change({set: {field: field.id, pfield: undefined, farm: field.farm ? field.farm.id : undefined}});
            });
        });

        $scope.$on("mapService:fieldClick", function (event, data) {
            var field = _view.getFieldById(data[0].fieldId);
            _v.change({set: {field: field.id, pfield: undefined, farm: field.farm ? field.farm.id : undefined}});
        });

        //return from map edit mode
        function returnToMapView() {
            localStorage.setItem('noSplashScreen', true);
            window.location.href = _v.getUrl({set: {field: undefined, pfield: undefined, farm: undefined, granularity: undefined}});
        }

        function saveChangesToMap() {
            $scope.mapLoading = true;

            var allSimple = true;
            var simpleErrorText = "";
            _.each(_view.getFields(), function(field) {
                if (!_mapFieldService.isSimple(field.id)) {
                    allSimple = false;
                    simpleErrorText = simpleErrorText + field.name + "<br>";
                }
            });
            if (!allSimple) {
                Notification.error(gettextCatalog.getString("Following fields contains intersections: " + simpleErrorText));
                $scope.mapLoading = false;
                return
            }

            var farms = _view.getFarms();
            _.each(farms, function(farm) {
                if (farm.newFarm) {
                    farm.tempId = farm.id;
                    delete farm.id; //delete farm.id to indicate that farm is new
                }
            });

            _apiService.updateFarms(_view.getCurrentAccount(), farms).then(function(d) {
                var idMap = d.data;
                var fields = [];
                _.each(_view.getFields(), function(field) {
                    // Don't save deleted fields
                    if (field.deleted) {
                        return;
                    }
                    var jsonField = {};
                    _mapFieldService.addCoordinates(jsonField, field.id);
                    if (!field.newField) {
                        jsonField.id = field.id; //delete field.id to indicate that field is new
                    }
                    jsonField.farm = field.farm ? field.farm.id : null;
                    if (jsonField.farm && _.size(_.filter(farms, function(farm) {return farm.newFarm && farm.tempId && farm.tempId == jsonField.farm;})) > 0 ) {
                        jsonField.farm = idMap[jsonField.farm];
                    }
                    jsonField.name = field.name;
                    fields.push(jsonField);
                });

                _apiService.updateFields(_view.getCurrentAccount(), fields).then(
                    function() {
                        _flashService.put("successMessage", _i18n.getString("settings.notifications.updateFields.success"));
                        returnToMapView();
                    },
                    function(d) {
                        $scope.mapLoading = false;
                        var errorsText = "<br>";
                        if (d.data.errors) {
                            _.each(d.data.errors, function(e) {
                                errorsText = errorsText + e.message + '<br>';
                            })
                        }
                        Notification.error({message: _i18n.getString("common.notifications.apiError") + errorsText, delay: null});
                    }
                );
            },
            function() {
                $scope.mapLoading = false;
                Notification.error({message: _i18n.getString("common.notifications.apiError")});
            });
        }

        function init() {
            if (_view.is.map() && _view.getCurrentAccount()) {
                $scope.mapLoading = true;
                _mapFieldService.clear();
                _apiService.fields(_view.getCurrentAccount()).then(
                    function(d) {
                        _.each(d.data.fields, function(field) {
                            _mapFieldService.highlightField(field);
                        });

                        colorActiveField();

                        //setup field colors
                        _apiService.allCrops(_view.getCurrentAccount(), false, true).then(function(response) {
                                var groupByField = _.groupBy(response.data, function(s){return s.field.id});

                                _.each(groupByField, (crops, fieldId) => _mapService.decorateField(fieldId, crops));

                                _mapService.colorFields();

                                $scope.mapLoading = false;
                                firstPan();
                                $rootScope.$broadcast("_map:mapReady");

                                loadNDVI();
                            },
                            function() {
                                $scope.mapLoading = false;
                                Notification.error({message: _i18n.getString("common.notifications.apiError"), delay: null});
                            });
                    },
                    function() {
                        $scope.mapLoading = false;
                        Notification.error({message: _i18n.getString("common.notifications.apiError"), delay: null});
                    }
                );
            }
        }

        function firstPan() {
            _mapFieldService.resize();
            if (_view.hasMapPositionParams()) {
                _mapFieldService.panToLatLngZoom(_view.getLatLngZoom());
                _mapFieldService.enableUrlChangeOnMapMove();
            } else {
                _mapFieldService.enableUrlChangeOnMapMove();
                if (_view.isFieldSelected() || _view.isFarmAndFieldSelected() || _view.isFarmSelected() || _view.isPanelFieldSelected()) {
                    panToCurrent();
                } else {
                    if (_view.getNavigation().farms.length > 0) {
                        _flashService.put('panRequiredEvent', true);
                        _v.change({set: {field: undefined, pfield: undefined, farm: _view.getNavigation().farms[0].id}});
                        return;
                    }
                    if (_view.getNavigation().standaloneFields.length > 0) {
                        _flashService.put('panRequiredEvent', true);
                        _v.change({set: {field: _view.getNavigation().standaloneFields[0].id, pfield: undefined, farm: undefined}});
                        return;
                    }
                    //nowhere to pan, try browser location
                    _mapFieldService.tryHTML5geolocation();
                }
            }
        }

        function panToCurrent() {
            if (_view.isFieldSelected() || _view.isFarmAndFieldSelected()) {
                _mapFieldService.panToFields([_view.getCurrentField()]);
            } else if (_view.isPanelFieldSelected()) {
                _mapFieldService.panToFields([_view.getPanelField()]);
            } else if (_view.isFarmSelected()) {
                _mapFieldService.panToFields(_view.getCurrentFarm().fields);
            }
        }

    });
